<template>
	<div>
		<!-- 登录信息 -->
		<!-- <global-tips></global-tips> -->
			<el-tabs type="border-card" @tab-click="change">
			  <el-tab-pane :label="item.name" v-for="(item,index) in ls" :key="item.id">
				  <div class="w-100 h-auto flex">
				  	<div class="h-auto flex flex-column bg-f8" style="width: 250px;" v-if="users.length > 0">
				  		<div class="w-100 flex align-center border-bottom px-2 bx position-relative" :class="user_index == key ? 'active' : ''" style="height: 70px;cursor: pointer;" v-for="(val,key) in users" :key="val.id" @click="handerUser(key)">
				  			{{val.username ||''}}
							<div class="position-absolute right-0 bottom-0 flex-all font-sm text-white" 
							style="width: 70px;height: 22px;border-top-left-radius: 8px;background:linear-gradient(to top right,#FD5501,#F5A074);z-index:99;"
							@click.stop="clear(item.id,val.user_id)"
							v-if="user_index == key">
							清空记录
							</div>
				  		</div>
				  	</div>
				  	<div class="h-auto flex flex-column p-2 bx">
						<div class="flex align-center bg-f8 p-2 bx rounded15 mb-2" style="min-width: 400px;" v-for="(v,k) in message" :key="v.id">
							<img src="@/assets/images/logo.jpg" class="rounded-circle" style="width: 35px;height: 35px;">
							<div class="flex flex-column pl-2 bx">
								<div class="flex align-center font-weight-bold">
									{{v.username || ''}}
								</div>
								<div class="flex align-center mt-1">
									{{v.data || ''}}
								</div>
							</div>
						</div>
					</div>
				  </div>
			  </el-tab-pane>
			</el-tabs>
	</div>
</template>

<script>
	import GlobalTips from "@/components/GlobalTips";
	import common from "@/common/mixins/common.js"
	export default {
		inject:['app'],
		mixins:[common],
		components: {
			GlobalTips,
		},
		data() {
			return {
				preUrl: 'message',
				autoRequest: false,
				queryForm: {
					page: 1,
					limit: 10,
					keyword: '',
					user_id:'',
				},
				form:{
					username:'',
					password:'',
					mobile:'',
					role_ids:[],
					status:1,
					avatar:'',
					url:'',
				},
				rules:{
					username: [
					  { required: true, message: '请输入名称', trigger: 'blur' }
					],
					role_ids: [
					  { required: true, message: '请选择角色', trigger: ['blur', 'change'] }
					],
				},
				tab_index:0,
				ls:[],
				users:[],
				user_index:0,
				page:1,
				limit:10,
				message:[],
			}
		},
		mounted() {
			this.getLsList()
		},
		methods:{
			reset() {
			  this.form = {
			   username:'',
			   password:'',
			   mobile:'',
			   role_ids:[],
			   status:1,
			   avatar:'',
			   url:'',
			  }
			  this.resetForm('form')
			},
			getLsList(){
				this.axios.get('/manage/user/ls').then(res=>{
					console.log(res)
					if(res.status){
						this.ls = res.data
						if(res.data.length > 0){
							this.queryForm.user_id = res.data[0].id
							this.getList()
						}
					}
				})
			},
			change(e){
				this.tab_index = JSON.parse(e.index)
				this.queryForm.user_id = this.ls[this.tab_index].id
				this.getList()
			},
			getList(){
				this.axios.get('/manage/message/list',{
					params:this.queryForm
				}).then(res=>{
					if(res.status){
						this.users = res.data
						this.message = []
						if(res.data.length > 0){
							this.messages()
						}
					}
				})
			},
			handerUser(index){
				if(this.user_index != index){
					this.user_index = index
					this.messages()
				}
			},
			messages(){
				this.axios.get('/manage/message/message',{
					params:{
						page:this.page,
						limit:this.limit,
						user_id:this.users[this.user_index].user_id,
						to_id:this.users[this.user_index].to_id
					}
				}).then(res=>{
					if(res.status){
						this.message = []
						this.total = parseInt(res.total)
						this.message = res.data.list.data
					}
				})
			},
			clear(to_id,id){
			  this.$confirm('确定要清空吗？', '提示', {
			    confirmButtonText: '清空',
			    cancelButtonText: '取消',
			    type: 'warning'
			  }).then(() => {
			    this.loading = true
			    this.axios.post('/manage/'+this.preUrl + '/clear',{
			         to_id:to_id,
			         id:id,
			      }).then(res => {
			  		if (res.status) {
			  		  this.$message({
			  			message: '清空成功',
			  			type: 'success'
			  		  })
			  		  this.getList()
			  		}
			  		this.loading = false
			    }).catch(err => {
			         this.loading = false
			    })
			  })
			},
		}
	}
</script>

<style scoped>
.active{
	background-color: #999;
}
</style>